// extracted by mini-css-extract-plugin
export var fifth_section = "jobDescription-module--fifth_section--870Kk";
export var first_section = "jobDescription-module--first_section--1PyYp";
export var fourth_section = "jobDescription-module--fourth_section--pVIew";
export var job_details_banner = "jobDescription-module--job_details_banner--GO4kS";
export var job_details_banner_inner = "jobDescription-module--job_details_banner_inner--8eLvR";
export var job_details_banner_inner_button = "jobDescription-module--job_details_banner_inner_button--2Y4ZZ";
export var job_details_banner_inner_flex = "jobDescription-module--job_details_banner_inner_flex--SEzLA";
export var job_details_wrapper = "jobDescription-module--job_details_wrapper--whzTe";
export var second_section = "jobDescription-module--second_section--PjwDu";
export var section = "jobDescription-module--section--vp4OT";
export var section_btn = "jobDescription-module--section_btn--bVZ4J";
export var third_section = "jobDescription-module--third_section--pnbr-";