import { graphql } from 'gatsby';
import React from 'react';
import * as styles from '../Career/styles/jobDescription.module.css';
import Layout from '../components/Layout';

export default function JobDescription({ data }) {
  const {
    description,
    digital_design,
    digital_design_paragraph2,
    digital_design_paragraph3,
    id,
    location,
    mission,
    position,
    role,
    slug,
    team_info,
    team_info_paragraph2,
    team_info_paragraph3,
    team,
    tenure,
    mission_paragraph2,
    mission_paragraph3,
    list,
    culture_par1,
    culture_par2,
    culture_list,
    task1,
    task2,
    task3,
    task4,
    task5,
  } = data.jobDummyJson;

  console.log(list);

  return (
    <Layout>
      <div className={styles.job_details_wrapper}>
        <div className={styles.job_details_banner}>
          <div className={styles.job_details_banner_inner}>
            <p>{team}</p>
            <h3>{description}</h3>
            <div className={styles.job_details_banner_inner_flex}>
              <h6>{location} - </h6>
              <h6> {tenure}</h6>
            </div>
            <button className={styles.job_details_banner_inner_button}>
              apply now
            </button>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.first_section}>
            <h5>LabLamb Works’ Commitment and Mission</h5>
            <p>{mission}</p>
            <p>{mission_paragraph2}</p>
            <p>{mission_paragraph3}</p>
          </div>

          <div className={styles.second_section}>
            <h5>About the team you'll be joining</h5>
            <p>{team_info}</p>
            <p>{team_info_paragraph2}</p>
            <p>{team_info_paragraph3}</p>
          </div>

          <div className={styles.third_section}>
            <h5>About Digital Design</h5>
            <p>{digital_design}</p>
            <p>{digital_design_paragraph2}</p>
            <p>{digital_design_paragraph3}</p>
          </div>

          <div className={styles.fourth_section}>
            <h5>What you'll do...</h5>
           
              <div>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task1}
                </p>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task2}
                </p>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task3}
                </p>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task4}
                </p>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task5}
                </p>
              </div>
           
          </div>

          <div className={styles.fourth_section}>
            <h5>What we're looking for...</h5>
            
              <div>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task1}
                </p>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task2}
                </p>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task3}
                </p>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task4}
                </p>
                <p>
                  <img src='/dot.png' alt='' />
                  {data.task5}
                </p>
              </div>
          
          </div>

          <div className={styles.fifth_section}>
            <h5>Working at LabLamb Works</h5>
            <p>{culture_par1}</p>
            <p>{culture_par2}</p>
            <div>
              {culture_list.map((data) => (
                <div>
                  <p>
                    <span>.</span>
                    {data.cl1}
                  </p>
                  <p>
                    <span>.</span>
                    {data.cl2}
                  </p>
                  <p>
                    <span>.</span>
                    {data.cl3}
                  </p>
                  <p>
                    <span>.</span>
                    {data.cl4}
                  </p>
                </div>
              ))}
              <p>
                <i>
                  We make hiring decisions based on your experience, skills and
                  passion. Please note that interviews are conducted
                  <br />
                  virtually. When you apply, please tell us the pronouns you use
                  and any reasonable adjustments you may need during
                  <br /> the interview process.
                </i>
              </p>

              <button className={styles.section_btn}>apply now</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query jobDetails($slug: String) {
    jobDummyJson(slug: { eq: $slug }) {
      description
      digital_design
      digital_design_paragraph2
      digital_design_paragraph3
      id
      location
      mission
      position
      role
      slug
      team_info
      team_info_paragraph2
      team_info_paragraph3
      team
      tenure
      mission_paragraph2
      mission_paragraph3

      task1
      task2
      task3
      task4
      task5

      culture_par1
      culture_par2
      culture_list {
        cl1
        cl2
        cl3
        cl4
      }
    }
  }
`;
